import {
  required,
  maxLength,
  minLength,
} from 'vuelidate/lib/validators';

const alphaNumCustom = (value) => /^[\w\u00f1\u00d1 \-]+$/g.test(value);

export default () => {
  return {
    circuitInfo: {
      CircuitId: {},
      CompanyBranchId: {},
      CircuitNameEs: { minLength: minLength(3), maxLength: maxLength(100),  required, alphaNumCustom },
      CircuitNameEn: { minLength: minLength(3), maxLength: maxLength(100),  required, alphaNumCustom },
      Order: { required },
      Color: { required },
      FgRequired: {},
      FgCrane: {},
      FgWeighingScale: {},
      FgMachine: {},
      FgYard: {},
      FgHold: {},
      FgRadioActivityLevel: {},
      FgApplyDate: {},
      FgApplyDateRange: {},
      Status: {},
    },
  }
}